import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  ActivationEnd,
  Router,
  RouterOutlet,
  RouterModule,
} from "@angular/router";
import { HeaderComponent } from "@components/header/header.component";
import { SidenavComponent } from "@components/sidenav/sidenav.component";
import { BreadcrumbComponent } from "./shared/components/breadcrumb/breadcrumb.component";
import { Subscription } from "rxjs";
import { GetErrorMessage } from "@store/appmanager/selector";
import { Store } from "@ngrx/store";
import { AppState } from "./store";
import { SnackbarService } from "@services/snackbar.service";
import { clearErrorMessage } from "@store/appmanager/actions";
import { CdkScrollable } from "@angular/cdk/scrolling";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [
    RouterOutlet,
    CdkScrollable,
    HeaderComponent,
    SidenavComponent,
    RouterModule,
    BreadcrumbComponent,
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit, OnDestroy {
  title = "hmis-revamp";
  showSideNav = false;
  screenWidth!: number;
  private errorSubcription = new Subscription();

  constructor(
    private router: Router,
    private store: Store<AppState>,
    private snackbarService: SnackbarService
  ) {}

  openSidebar = true;

  handleSidebarToggle() {
    this.openSidebar = !this.openSidebar;
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1320) {
      this.openSidebar = false;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        this.showSideNav = event?.snapshot.data["showSideNav"] !== false;
      }
    });

    this.errorSubcription.add(
      this.store.select(GetErrorMessage).subscribe((error) => {
        if (!!error) {
          this.snackbarService.showError(error);
          this.store.dispatch(clearErrorMessage({ payload: "true" }));
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.errorSubcription.unsubscribe();
  }
}
