import { inject, Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { clearErrorMessage, clearErrorMessageSuccess, errorMessage, getAppoinmentCategoryList, getAppoinmentCategoryListSuccess } from './actions';
import { catchError, map, mergeMap, of } from "rxjs";
import { Router } from "@angular/router";
import { CommonService } from "@services/common.service";
import { APPOINTMENT_API_URL } from "@core/api/appointment";

@Injectable()
export class AppointmentEffects {
  private action$ = inject(Actions);
  constructor(
    private commonService: CommonService, 
    private router: Router
) {}

getAppoinmentCategoryList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getAppoinmentCategoryList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.GET_CATEGORY_SESSIONS)
          .pipe(
            map((resp) => {
              return getAppoinmentCategoryListSuccess({
                appoinmentCategoryListData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

clearErrorMessage$ = createEffect(() =>
    this.action$.pipe(
      ofType(clearErrorMessage),
      map(() => {
        return clearErrorMessageSuccess();
      })
    )
  );
  
}