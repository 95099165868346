import { ActionReducerMap } from "@ngrx/store";
import { AuthState, authReducer } from "./auth/reducer";
import { DashboardState, dashboardReducer } from "./dashboard/reducers";
import { AppManagerState, appManagerReducer } from "./appmanager/reducer";
export interface AppState{
    authState: AuthState,
    dashboardState: DashboardState,
    appManagerState: AppManagerState,
}

export const reducers: ActionReducerMap<AppState> = {
    authState: authReducer,
    dashboardState: dashboardReducer,
    appManagerState: appManagerReducer
}