<div
  class="sidebar"
  [class.sidebar-close]="!openSidebar"
  (mouseenter)="mouseenter()"
  (mouseleave)="mouseleave()"
>
  <div class="search_box">
    <app-input
      type="text"
      name="search"
      [isLabel]="false"
      placeholder="Search"
      [control]="getControl('search')"
      [formsubmitted]="formsubmitted"
      [required]="false"
      [leftSearchIcon]="true"
      [controlKeyIcon]="true"
    ></app-input>
  </div>

  <ul class="nav-links" id="nav-links">
    <li
      *ngFor="let item of menuItems"
      #itemEl
      routerLinkActive="active-sidemenu"
      [attr.data-menu-label]="item.label"
    >
      <div *ngIf="item.subItems.length == 0" class="dropdown-title">
        <a [routerLink]="[item.link]">
          <span class="link_name">{{ item.label }}</span>
        </a>
      </div>
      <div
        *ngIf="item.subItems.length > 0"
        class="dropdown-title"
        (click)="showSubmenu(itemEl, item.label)"
      >
        <a>
          <div>
            <mat-icon class="svg_box" svgIcon="{{ item.icon }}"></mat-icon>
            <span class="link_name">{{ item.label }}</span>
          </div>

          <div class="sideBarDropdown">
            <mat-icon svgIcon="sideBarDropdownIcon"></mat-icon>
          </div>
        </a>
      </div>
      <ul class="sub-menu" [class.blank]="item.subItems.length == 0">
        <li
          *ngFor="let item_sub of item.subItems"
          routerLinkActive="selected-menu-items"
        >
          <a [routerLink]="[item_sub.link]" class="sub-menu-link">
            <div class="inner-list">
              <svg
                width="7"
                height="6"
                viewBox="0 0 7 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="1"
                  y="0.5"
                  width="5"
                  height="5"
                  rx="2.5"
                  stroke="#242E4C"
                />
              </svg>

              <!-- </ng-template> -->
              <a class="sub-link">{{ item_sub.label }}</a>
            </div>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</div>
<!-- border: 1px solid #242E4C -->

<!-- <fa-icon [icon]="faTachometer"></fa-icon> -->
<!-- <em  class="{{item.icon}}"></em> -->
<!-- <i class="fa fa-tachometer" aria-hidden="true"></i> -->
<!-- <i class="fa-solid fa-gauge-simple-high"></i> -->
<!-- <i class="fas fa-tachometer"></i> -->
<!-- <i class="fas fa-spinner fa-spin"></i> -->
<!-- <i [ngClass]="fa fa-home"></i> -->
