export const getTodayInfo = 
{
    "newborn": {
        "NewBornCount": 0
    },
    "patient": {
        "RegistrationCount": 0,
        "DeseasedCount": 0
    },
    "appointment": {
        "AppointmentCount": 0,
        "FollowupCount": 0
    },
    "refund": [
        {
            "Key": "OP Pharmacy Refund",
            "Value": {
                "DisplayOrder": 11,
                "BillAmount": null,
                "CashAmount": null,
                "CardAmount": "0.00",
                "OtherAmount": "0.00"
            }
        },
        {
            "Key": "IP Pharmacy Return",
            "Value": {
                "DisplayOrder": 101,
                "BillAmount": null,
                "CashAmount": "0.00",
                "CardAmount": "0.00",
                "OtherAmount": "0.00"
            }
        }
    ],
    "categorycollection": [
        {
            "Key": 1,
            "Value": {}
        },
        {
            "Key": 2,
            "Value": {}
        }
    ],
    "billrefund": [
        {
            "Key": "Bill Refund (OP & IP)",
            "Value": {
                "DisplayOrder": 4,
                "BillAmount": null,
                "CashAmount": null,
                "CardAmount": null,
                "OtherAmount": null
            }
        }
    ],
    "encounter": {
        "OPVisitCount": 0,
        "opNewVisitCount": 0,
        "opFollowUpVisitCount": 0,
        "AdmissionCount": 0,
        "DischargeCount": 0
    },
    "opdiscount": [
        {
            "Key": "OP Discount",
            "Value": null
        },
        {
            "Key": "DG Discount",
            "Value": null
        },
        {
            "Key": "IP Discount",
            "Value": null
        },
        {
            "Key": "PHARMACY Discount",
            "Value": null
        },
        {
            "Key": "Cancel",
            "Value": {
                "OPCancel": null,
                "DGCancel": null,
                "IPCancel": null,
                "PharmacyOPCancel": null,
                "PharmacyIPCancel": null,
                "PharmacyDirectCancel": null
            }
        },
        {
            "Key": "Discount",
            "Value": {
                "OPDiscount": null,
                "DGDiscount": null,
                "IPDiscount": null,
                "PharmacyOPDiscount": null,
                "PharmacyIPDiscount": null,
                "PharmacyDirectDiscount": null
            }
        },
        {
            "Key": "Credit",
            "Value": {
                "OPCredit": null,
                "DGCredit": null,
                "IPCredit": null,
                "PharmacyOPCredit": null,
                "PharmacyIPCredit": null,
                "PharmacyDirectCredit": null
            }
        }
    ],
    "ipdiscount": [
        {
            "Key": "OP Discount",
            "Value": null
        },
        {
            "Key": "DG Discount",
            "Value": null
        },
        {
            "Key": "IP Discount",
            "Value": null
        },
        {
            "Key": "PHARMACY Discount",
            "Value": null
        },
        {
            "Key": "Cancel",
            "Value": {
                "OPCancel": null,
                "DGCancel": null,
                "IPCancel": null,
                "PharmacyOPCancel": null,
                "PharmacyIPCancel": null,
                "PharmacyDirectCancel": null
            }
        },
        {
            "Key": "Discount",
            "Value": {
                "OPDiscount": null,
                "DGDiscount": null,
                "IPDiscount": null,
                "PharmacyOPDiscount": null,
                "PharmacyIPDiscount": null,
                "PharmacyDirectDiscount": null
            }
        },
        {
            "Key": "Credit",
            "Value": {
                "OPCredit": null,
                "DGCredit": null,
                "IPCredit": null,
                "PharmacyOPCredit": null,
                "PharmacyIPCredit": null,
                "PharmacyDirectCredit": null
            }
        }
    ],
    "ippharmacydue": [
        {
            "Key": "OP Discount",
            "Value": null
        },
        {
            "Key": "DG Discount",
            "Value": null
        },
        {
            "Key": "IP Discount",
            "Value": null
        },
        {
            "Key": "PHARMACY Discount",
            "Value": null
        },
        {
            "Key": "Cancel",
            "Value": {
                "OPCancel": null,
                "DGCancel": null,
                "IPCancel": null,
                "PharmacyOPCancel": null,
                "PharmacyIPCancel": null,
                "PharmacyDirectCancel": null
            }
        },
        {
            "Key": "Discount",
            "Value": {
                "OPDiscount": null,
                "DGDiscount": null,
                "IPDiscount": null,
                "PharmacyOPDiscount": null,
                "PharmacyIPDiscount": null,
                "PharmacyDirectDiscount": null
            }
        },
        {
            "Key": "Credit",
            "Value": {
                "OPCredit": null,
                "DGCredit": null,
                "IPCredit": null,
                "PharmacyOPCredit": null,
                "PharmacyIPCredit": null,
                "PharmacyDirectCredit": null
            }
        }
    ],
    "pharmacydiscount": [
        {
            "Key": "OP Discount",
            "Value": null
        },
        {
            "Key": "DG Discount",
            "Value": null
        },
        {
            "Key": "IP Discount",
            "Value": null
        },
        {
            "Key": "PHARMACY Discount",
            "Value": null
        },
        {
            "Key": "Cancel",
            "Value": {
                "OPCancel": null,
                "DGCancel": null,
                "IPCancel": null,
                "PharmacyOPCancel": null,
                "PharmacyIPCancel": null,
                "PharmacyDirectCancel": null
            }
        },
        {
            "Key": "Discount",
            "Value": {
                "OPDiscount": null,
                "DGDiscount": null,
                "IPDiscount": null,
                "PharmacyOPDiscount": null,
                "PharmacyIPDiscount": null,
                "PharmacyDirectDiscount": null
            }
        },
        {
            "Key": "Credit",
            "Value": {
                "OPCredit": null,
                "DGCredit": null,
                "IPCredit": null,
                "PharmacyOPCredit": null,
                "PharmacyIPCredit": null,
                "PharmacyDirectCredit": null
            }
        }
    ],
    "receipt": [
        {
            "Key": "OP Bills",
            "Value": {
                "DisplayOrder": 1,
                "BillAmount": null,
                "CashAmount": null,
                "CardAmount": null,
                "OtherAmount": null,
                "UPIAmount": null
            }
        },
        {
            "Key": "DG Bills",
            "Value": {
                "DisplayOrder": 1,
                "BillAmount": null,
                "CashAmount": null,
                "CardAmount": null,
                "OtherAmount": null,
                "UPIAmount": null
            }
        },
        {
            "Key": "Inpatient Bills",
            "Value": {
                "DisplayOrder": 2,
                "BillAmount": null,
                "CashAmount": null,
                "CardAmount": null,
                "OtherAmount": null,
                "UPIAmount": null
            }
        },
        {
            "Key": "Pharmacy OP Bills",
            "Value": {
                "DisplayOrder": 10,
                "BillAmount": null,
                "CashAmount": null,
                "CardAmount": null,
                "OtherAmount": null,
                "UPIAmount": null
            }
        },
        {
            "Key": "Pharmacy IP Bills",
            "Value": {
                "DisplayOrder": 10,
                "BillAmount": null,
                "CashAmount": null,
                "CardAmount": null,
                "OtherAmount": null,
                "UPIAmount": null
            }
        },
        {
            "Key": "Pharmacy Direct Bills",
            "Value": {
                "DisplayOrder": 10,
                "BillAmount": null,
                "CashAmount": null,
                "CardAmount": null,
                "OtherAmount": null,
                "UPIAmount": null
            }
        }
    ]
}