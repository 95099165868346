import { inject, Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import {
  addPatient,
  addPatientSuccess,
  clearErrorMessage,
  clearErrorMessageSuccess,
  errorMessage,
  getPatientSearch,
  getPatientSearchSuccess,
  getUserProfile,
  getUserProfileSuccess,
  getUserSign,
  getUserSignSuccess,
} from "./actions";

import { catchError, map, mergeMap, of } from "rxjs";
import { Router } from "@angular/router";
import { CommonService } from "@services/common.service";
import { REGISTRAION_API_URL } from "@core/api/registration";
@Injectable()
export class RegisterEffects {
  private action$ = inject(Actions);
  constructor(private commonService: CommonService, private router: Router) {}

  getPatientSearch$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPatientSearch),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.PATIENT_SEARCH)
          .pipe(
            map((tempResp) => {
              return getPatientSearchSuccess({
                patientSearchData: tempResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getUserProfile$ = createEffect(() =>
    this.action$.pipe(
      ofType(getUserProfile),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_USER_PROFILE)
          .pipe(
            map((profileResp) => {
              console.log('profileResp', profileResp)
              return getUserProfileSuccess({
                userProfileData: profileResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getUserSign$ = createEffect(() =>
    this.action$.pipe(
      ofType(getUserSign),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_USER_SIGNATURE)
          .pipe(
            map((signResp) => {
              return getUserSignSuccess({
                userSignData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addPatient$ = createEffect(() =>
    this.action$.pipe(
      ofType(addPatient),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_PATIENT)
          .pipe(
            map((signResp) => {
              return addPatientSuccess({
                addPatientData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  clearErrorMessage$ = createEffect(() =>
    this.action$.pipe(
      ofType(clearErrorMessage),
      map(() => {
        return clearErrorMessageSuccess();
      })
    )
  );
}
