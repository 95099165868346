import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AppState } from '@store/index';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import { getcontrolCompleted, ValidToken } from '@store/auth/selector';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private store: Store<AppState>,
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.pipe(
      select(ValidToken),
      tap(isAuthenticated => {
        return (isAuthenticated) ? true : this.router.navigate(['/auth/login']);
      })
    );
  }
  
}
