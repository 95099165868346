import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from "@angular/core";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { AppState } from "@store/index";
import { Store } from "@ngrx/store";
import { logout } from "@store/auth/actions";
import { MatButtonModule } from "@angular/material/button";
import { SafeHtml } from "@angular/platform-browser";
import { UtilsService } from "@services/utils.service";
import { CommonModule } from "@angular/common";
import { ButtonComponent } from "@reusableComponents/button/button.component";
import { SelectComponent } from "@reusableComponents/select/select.component";
import { Router, RouterLink } from "@angular/router";
import { Subscription } from "rxjs";
import { getUserObject, loginUserId } from "@store/auth/selector";
import { MatMenuModule } from "@angular/material/menu";
import { IconModule } from "@shared/icon.module";

@Component({
  selector: "app-header",
  standalone: true,
  imports: [
    RouterLink,
    MatToolbarModule,
    MatButtonModule,
    CommonModule,
    ButtonComponent,
    SelectComponent,
    MatMenuModule,
    IconModule
  ],
  templateUrl: "./header.component.html",
  styleUrl: "./header.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  settingsIcon!: SafeHtml;
  dropdownDownArrowIcon3!: SafeHtml;
  private logoutSubscription: Subscription = new Subscription();
  tabLogo: boolean = false;
  mobileLogo: boolean = false;
  @Input() openSidebar: boolean = true;
  @Output() toggleSidebar = new EventEmitter<void>();
  @Output() toggleSidebarEvent = new EventEmitter<void>();
  menuOptions: any[] = [];
  screenWidth!: number;
  public user: any;
  public userName: string = "";
  constructor(
    private store: Store<AppState>,
    private utilsService: UtilsService,
    private router: Router
  ) {
    this.store.select(getUserObject).subscribe(user => {
      if(user){
        this.user = user;
        this.userName = `${this.user?.Title?.Description} ${this.user?.FirstName} ${this.user?.LastName}`
      }
    })
  }

  ngOnInit(): void {
    this.settingsIcon = this.utilsService.settingsIcon();
    this.dropdownDownArrowIcon3 = this.utilsService.dropdownDownArrowIcon3();

    // this.menuOptions = [
    //   { label: 'Change Password', icon: this.kieyIcons, action: null },
    //   { label: 'Log out', icon: this.logoutIcon, action: () => this.isLogout() },
    // ];
  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   this.screenWidth = window.innerWidth;

  //   if (this.screenWidth < 768) {
  //     this.mobileLogo = true
  //   }
  // }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.adjustRows(event.target.innerWidth);
  }

  adjustRows(width: number): void {
    if (width <= 1320) {
      this.tabLogo = true;
    }

    if (width <= 768) {
      this.mobileLogo = true;
    }
  }

  onToggleSidebar() {
    this.toggleSidebarEvent.emit();
  }

  isLogout() {
    this.logoutSubscription.add(
      this.store.select(loginUserId).subscribe((userId) => {
        if (userId !== 0) {
          let payload = {
            UserId: userId,
          };
          this.store.dispatch(logout({ payload }));
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.logoutSubscription.unsubscribe();
  }
}
