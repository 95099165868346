import { createReducer, Action, on } from "@ngrx/store";
import { addPatient, addPatientSuccess, clearErrorMessage, clearErrorMessageSuccess, errorMessage, getPatientSearch, getPatientSearchSuccess, getUserProfile, getUserProfileSuccess, getUserSign, getUserSignSuccess } from "./actions";
export interface RegistrationState {
  patientSearchData: any;
  errorData: any;
  userProfileData: any;
  userSignData: any;
  addPatientData: any;
}

export const initialState: RegistrationState = {
  patientSearchData: undefined,
  errorData: undefined,
  userProfileData: undefined,
  userSignData: undefined,
  addPatientData: undefined
};

const _reducer = createReducer(
  initialState,
  on(getPatientSearch, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPatientSearchSuccess, (state, { patientSearchData }) => ({
    ...state,
    patientSearchData,
    errorData: null,
  })),
  on(getUserProfile, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getUserProfileSuccess, (state, { userProfileData }) => ({
    ...state,
    userProfileData,
    errorData: null,
  })),
  on(getUserSign, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getUserSignSuccess, (state, { userSignData }) => ({
    ...state,
    userSignData,
    errorData: null,
  })),
  on(addPatient, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addPatientSuccess, (state, { addPatientData }) => ({
    ...state,
    addPatientData,
    errorData: null,
  })),
  on(errorMessage, (state, { errorData }) => ({
    ...state,
    errorData,
  })),
  on(clearErrorMessage, (state) => ({
    ...state,
    errorData: null,
  })),
  on(clearErrorMessageSuccess, (state) => ({
    ...state,
    errorData: null,
  }))
);

export function registrationReducer(
  state: RegistrationState | undefined,
  action: Action
) {
  return _reducer(state, action);
}
