import { createReducer, Action, on } from "@ngrx/store";
import { clearErrorMessage, clearErrorMessageSuccess, errorMessage, getAppoinmentCategoryList, getAppoinmentCategoryListSuccess } from './actions'

export interface AppointmentState {
    appoinmentCategoryListData: any;
    errorData: any;
  }
  
  export const initialState: AppointmentState = {
    errorData: undefined,
    appoinmentCategoryListData: undefined
  };

  const _reducer = createReducer(
    initialState,
    on(getAppoinmentCategoryList, (state) => ({
      ...state,
      errorData: null,
    })),
    on(getAppoinmentCategoryListSuccess, (state, { appoinmentCategoryListData }) => ({
      ...state,
      appoinmentCategoryListData,
      errorData: null,
    })),
    on(errorMessage, (state, { errorData }) => ({
        ...state,
        errorData,
      })),
      on(clearErrorMessage, (state) => ({
        ...state,
        errorData: null,
      })),
      on(clearErrorMessageSuccess, (state) => ({
        ...state,
        errorData: null,
      }))
    );

    export function appointmentReducer(
        state: AppointmentState | undefined,
        action: Action
      ) {
        return _reducer(state, action);
      }