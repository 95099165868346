<div class="nav">
  <mat-toolbar>
    <div class="left">
      <div routerLink="/dashboard/healthmed">
        @if(openSidebar){
        <img
          src="../../../assets/images/HealthmedLogo.png"
          alt="logo"
          class="logo"
        />
        } @else {
        <img
          src="../../../assets/images/HealthmedLogo2.png"
          alt="logo"
          class="logo2"
        />
        }
      </div>
      <button mat-icon-button (click)="onToggleSidebar()">
        <div class="svg_box" [innerHtml]="menuIcon"></div>
      </button>

      <div class="headline-3">{{user?.Facility?.FacilityName}}</div>
    </div>

    <div class="right">
      <div class="headerMenu">
        <button mat-icon-button>
          <div class="svg_box" [innerHtml]="notificationIcon"></div>
        </button>
        <small>Notification</small>
      </div>

      <div class="headerMenu" [matMenuTriggerFor]="languageMenu">
        <button mat-icon-button>
          <div class="svg_box" [innerHtml]="languageIcon"></div>
        </button>
        <button mat-button>
          <div class="language-menu-dropdown">
            English
            <span [innerHTML]="dropdownDownArrowIcon3"></span>
          </div>
        </button>
        <mat-menu #languageMenu="matMenu">
          <button mat-menu-item>English</button>
          <button mat-menu-item>Tamil</button>
        </mat-menu>
      </div>

      <div class="headerMenu">
        <button mat-icon-button>
          <div class="svg_box" [innerHtml]="appInfoIcon"></div>
        </button>
        <small>App Info</small>
      </div>

      <button mat-button [matMenuTriggerFor]="menu">
        <div class="user-profile">
          <div class="user-name">
            <div class="profileMenu-user-name">
              {{userName}}
            </div>
            <div class="profileMenu-user-role">
              {{user?.ClinicalRole?.Description}}</div>
          </div>
          <img
            src="../../../assets/images/user-img.png"
            alt="user"
            class="user-img"
          />
        </div>
      </button>

      <mat-menu #menu="matMenu" class="wrapper-user-menu" xPosition="before">
        <button mat-menu-item>
          <span class="svg_box" [innerHTML]="userIcon"></span>
          My Profile
        </button>
        <button mat-menu-item>
          <span class="svg_box" [innerHTML]="changePasswordIcon"></span>
          Change Password
        </button>
        <button mat-menu-item>
          <span class="svg_box" [innerHTML]="darkThemeIcon"></span>
          Dark Mode
        </button>
        <button mat-menu-item>
          <span class="svg_box" [innerHTML]="settingsIcon"></span>
          Settings
        </button>
        <button mat-menu-item (click)="isLogout()">
          <span class="svg_box" [innerHTML]="logoutIcon"></span>
          Log out
        </button>
      </mat-menu>
    </div>
    <span class="example-spacer"></span>
  </mat-toolbar>
</div>
