import { createReducer, Action, on } from "@ngrx/store";
import { getTodayInformation, getTodayInformationSuccess, getWardInformation, getWardInformationSuccess, logoutSuccess } from "./actions";

export interface DashboardState{
    todayInfo: any;
    wardInfo: any;
    loading: boolean;
    error: any;
}

export const initialState: DashboardState = {
    todayInfo: undefined,
    wardInfo: undefined,
    loading: false,
    error: undefined
}

const _reducer = createReducer(
    initialState,
    on(getTodayInformation, (state) => ({
        ...state,
        loading: true,
        error: null
    })),
    on(getTodayInformationSuccess, (state, { todayInfo }) => ({
        ...state,
        todayInfo,
        loading: false,
        error: null
      })),
    on(getWardInformation, (state) => ({
        ...state,
        loading: true,
        error: null
    })),
    on(getWardInformationSuccess, (state, { wardInfo }) => ({
        ...state,
        wardInfo,
        loading: false,
        error: null
      })),
      on(logoutSuccess, (state) => ({
        ...state,
        todayInfo: null,
        loading: false
      })),
)


export function dashboardReducer (state: DashboardState | undefined, action:Action){
    return _reducer(state, action)
}