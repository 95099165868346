import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatListItem, MatNavList } from "@angular/material/list";
// import {
//   MatDrawer,
//   MatDrawerContainer,
//   MatDrawerContent,
//   MatDrawerMode,
//   MatSidenavModule,
// } from '@angular/material/sidenav';
import { SafeHtml } from "@angular/platform-browser";
import { Router, RouterModule, RouterOutlet } from "@angular/router";
import { UtilsService } from "@services/utils.service";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { BreadcrumbComponent } from "@reusableComponents/breadcrumb/breadcrumb.component";
import { Store } from "@ngrx/store";
import { AppState } from "@store/index";
import { controlsList } from "@store/auth/selector";
import { InputComponent } from "@reusableComponents/input/input.component";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-sidenav",
  standalone: true,
  imports: [
    // MatDrawerContainer,
    // MatSidenavModule,
    // MatDrawerContent,
    // MatDrawer,
    RouterOutlet,
    MatNavList,
    MatListItem,
    MatIcon,
    RouterModule,
    CommonModule,
    BreadcrumbComponent,
    InputComponent,
  ],
  templateUrl: "./sidenav.component.html",
  styleUrl: "./sidenav.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit {
  searchForm: FormGroup;
  formsubmitted: boolean = false;
  screenWidth!: number;
  searchIcon!: SafeHtml;
  dropdownRightArrowIcon!: SafeHtml;
  dropdownDownArrowIcon!: SafeHtml;
  dotIcon!: SafeHtml;
  dotFilledIcon!: SafeHtml;
  AppMangerIcon!: SafeHtml;
  registrationIcon!: SafeHtml;
  appointmentIcon!: SafeHtml;
  billingIcon!: SafeHtml;
  activeMenu: string | null = null;

  menuItems: any[] = [];
  @Input() openSidebar: boolean = true;
  @Output() toggleSidebarEvent = new EventEmitter<void>();

  showSubmenu(itemEl: HTMLElement, menuLabel: string) {
    itemEl.classList.toggle("showMenu");

    if (itemEl.classList.contains('showMenu')) {
      this.activeMenu = menuLabel;
    } else {
      this.activeMenu = null;
    }
  }

  constructor(
    private fb: FormBuilder,
    public utilsService: UtilsService,
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2,
    private router: Router,
    private store: Store<AppState>
  ) {
    this.searchIcon = this.utilsService.searchIcon("#2C2E31");
    this.dropdownRightArrowIcon = this.utilsService.dropdownRightArrowIcon();
    this.dropdownDownArrowIcon = this.utilsService.dropdownDownArrowIcon();
    this.dotIcon = this.utilsService.dotIcon();
    this.dotFilledIcon = this.utilsService.dotFilledIcon();
    this.AppMangerIcon = this.utilsService.AppManagerIcon();
    this.registrationIcon = this.utilsService.registrationIcon();
    this.appointmentIcon = this.utilsService.appointmentIcon();
    this.billingIcon = this.utilsService.billingIcon();

    this.getControlList();

    this.searchForm = this.fb.group({
      search: [null],
    });
  }

  getControl(controlName: string) {
    return this.searchForm.get(controlName) as FormControl;
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1320) {
      this.openSidebar = false;
    }
    // this.toggleSidebarCloseClass();

    // this.menuItems = [
    //   {
    //     label: 'App manager',
    //     icon: this.AppMangerIcon,
    //     subItems: [
    //       { label: 'Event Template', link: '/appmanager/event-template' },
    //       { label: 'Organization', link: '/appmanager/organization' },
    //       { label: 'Facility', link: '/appmanager/facility' },
    //       { label: 'Departments', link: '/appmanager/departments' },
    //       { label: 'Roles', link: '/appmanager/roles' },
    //       { label: 'Groups', link: '/appmanager/groups' },
    //       { label: 'Speciality', link: '/appmanager/speciality' },
    //       { label: 'Users', link: '/appmanager/users' },
    //       { label: 'Reference Groups', link: '/appmanager/ref-groups' },
    //       { label: 'Modules', link: '/appmanager/modules' },
    //       { label: 'Sequence Masters', link: '/appmanager/seq-masters' },
    //       { label: 'Manager Events', link: '/appmanager/manage-events' },
    //       { label: 'ERP Integration', link: '/appmanager/erp-integration' },
    //       { label: 'Active Users', link: '/appmanager/users-active' },
    //       { label: 'Event Dashboard', link: '/appmanager/event-dashboard' },
    //       { label: 'Print Logs', link: '/appmanager/print-logs' },
    //       { label: 'Controls', link: '/appmanager/controls' },
    //       { label: 'Report Schedular', link: '/appmanager/report-shedular' },
    //     ],
    //     route: "/"
    //   },
    //   {
    //     label: 'Registration',
    //     icon: this.registrationIcon,
    //     subItems: [
    //       { label: 'Patient Search', link: '/registration/patient-search' },
    //       { label: 'Quick Registration', link: '/registration/quick-registration' },
    //     ],
    //     route: "/"
    //   },
    //   {
    //     label: 'Dashboard',
    //     icon: this.appointmentIcon,
    //     subItems: [
    //       { label: 'Healthmed Dashboard', link: '/dashboard/healthmed' },
    //     ],
    //     route: "/"
    //   },
    //   {
    //     label: 'Billing',
    //     icon: this.billingIcon,
    //     subItems: [
    //       { label: 'Submenu Item 3', link: '/submenu3' },
    //       { label: 'Submenu Item 4', link: '/submenu4' },
    //     ],
    //     route: "/"
    //   }
    // ]
  }

  ngAfterViewInit(): void {
    this.checkActiveRoute();
  }

  checkActiveRoute(): void {

    setTimeout(() => {
      const activeMenuEl = document.querySelector('.active-sidemenu');
      if (activeMenuEl) {
        activeMenuEl.classList.add('showMenu');
      }
    }, 0);
  }

  isRouteActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  subMenuOpen: { [key: string]: boolean } = {};

  mouseenter() {
    const sidebar = document.querySelector(".sidebar");
    if (!this.openSidebar) {
      this.renderer.removeClass(sidebar, "sidebar-close");
      this.toggleSidebarEvent.emit();
    }
  }

  mouseleave() {
    const sidebar = document.querySelector(".sidebar");
    if (!this.openSidebar) {
      this.renderer.addClass(sidebar, "sidebar-close");
      // this.toggleSidebarEvent.emit();
    }
  }

  getControlList() {
    this.store.select(controlsList).subscribe((controlList) => {
      if (controlList) this.setSideMenu(controlList);
    });
  }

  setSideMenu(controlList: any) {
    const menuList = controlList?.Data;
    interface MenuItem {
      label: string;
      icon: any;
      subItems: subMenuItem[];
    }

    interface subMenuItem {
      label: string;
      link: string;
    }

    const menu: MenuItem[] = [];

    const IconMap: any = {
      dotIcon: this.utilsService.dotIcon(),
      AppManagerIcon: this.utilsService.AppManagerIcon(),
      registrationIcon: this.utilsService.registrationIcon(),
      appointmentIcon: this.utilsService.appointmentIcon(),
      billingIcon: this.utilsService.billingIcon(),
      billingMasterIcon: this.utilsService.billingMasterIcon(),
      lisIcon: this.utilsService.lisIcon(),
      diagnosticMastersIcon: this.utilsService.diagnosticMastersIcon(),
      emrIcon: this.utilsService.emrIcon(),
      risIcon: this.utilsService.risIcon(),
      endoscopyIcon: this.utilsService.endoscopyIcon(),
      nuclearMedicineIcon: this.utilsService.nuclearMedicineIcon(),
      echoIcon: this.utilsService.echoIcon(),
      generalIcon: this.utilsService.generalIcon(),
      reportIcon: this.utilsService.reportIcon(),
      dashboardIcon: this.utilsService.dashboardIcon(),
      ipManagementIcon: this.utilsService.ipManagementIcon(),
      pharmacyIcon: this.utilsService.pharmacyIcon(),
      inventoryManagementIcon: this.utilsService.inventoryManagementIcon(),
      accountsPayableIcon: this.utilsService.accountsPayableIcon(),
      inventoryMastersIcon: this.utilsService.inventoryMastersIcon(),
      opticalManagementIcon: this.utilsService.opticalManagementIcon(),
      accidentEmergencyIcon: this.utilsService.accidentEmergencyIcon(),
      dietandKitchenIcon: this.utilsService.dietandKitchenIcon(),
      claimManagementIcon: this.utilsService.claimManagementIcon(),
      oTManagementlIcon: this.utilsService.oTManagementlIcon(),
      cssdManagementIcon: this.utilsService.cssdManagementIcon(),
      medicalCertificateIcon: this.utilsService.medicalCertificateIcon(),
      ambulatoryServiceIcon: this.utilsService.ambulatoryServiceIcon(),
      clinicalMaster: this.utilsService.clinicalMaster(),
      generalMasterIcon: this.utilsService.generalMasterIcon(),
      mrdIcon: this.utilsService.mrdIcon(),
      hrmMastersIcon: this.utilsService.hrmMastersIcon(),
      hrmManagementIcon: this.utilsService.hrmManagementIcon(),
      recruitmentsIcon: this.utilsService.recruitmentsIcon(),
      costManagementIcon: this.utilsService.costManagementIcon(),
      expenseIcon: this.utilsService.expenseIcon(),
      attendanceManagementIcon: this.utilsService.attendanceManagementIcon(),
      assetManagementIcon: this.utilsService.assetManagementIcon(),
      physiotherapyIcon: this.utilsService.physiotherapyIcon(),
      bmIcon: this.utilsService.bmIcon(),
      selfServiceIcon: this.utilsService.selfServiceIcon(),
      patientServicesIcon: this.utilsService.patientServicesIcon(),
      infectionControlsIcon: this.utilsService.infectionControlsIcon(),
      ophthalmologyEmrIcon: this.utilsService.ophthalmologyEmrIcon(),
      BioMedicalWasteManagementIcon:
        this.utilsService.BioMedicalWasteManagementIcon(),
      linenLaundryIcon: this.utilsService.linenLaundryIcon(),
      bloodBankIcon: this.utilsService.bloodBankIcon(),
      qmsIcon: this.utilsService.qmsIcon(),
      qualityManagementIcon: this.utilsService.qualityManagementIcon(),
      biReportsIcon: this.utilsService.biReportsIcon(),
    };

    menuList.forEach((parent: any) => {
      if (parent.ParentControlId === null) {
        const svgRef = parent.svgRef;
        const newMenuItem = {
          Id: parent.Id,
          DisplayOrder: parent.DisplayOrder,
          label: parent.Display,
          icon: IconMap[`${svgRef}`],
          // icon: parent.IconRef,
          subItems: this.setChilds(menuList, parent.Id),
        };
        menu.push(newMenuItem);
      }
    });

    this.menuItems = menu;
  }

  setChilds(list: any, id: number) {
    const filterChilds = list.filter((app: any) => app.ParentControlId === id);
    const setProp = filterChilds.map((item: any) => ({
      ...item,
      label: item?.Display,
      link: `/${item?.SRef.replace(/\./g, "/")}`,
    }));
    return setProp;
  }
}
