@if(showSideNav){
<div class="app">
  <app-header
    [openSidebar]="openSidebar"
    (toggleSidebarEvent)="handleSidebarToggle()"
  ></app-header>
  <div class="main-wrapper">
    <app-sidenav
      [openSidebar]="openSidebar"
      (toggleSidebarEvent)="handleSidebarToggle()"
    ></app-sidenav>
    <div class="main" cdkScrollable>
      <app-breadcrumb></app-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
} @else{
<router-outlet></router-outlet>
}
<!-- <router-outlet></router-outlet> -->
