export const getWardInfo = 
{
    "PageContext": {
        "PageSize": 1000,
        "PageNumber": 1,
        "TotalRecords": 7
    },
    "Data": [
        {
            "Id": 1,
            "FacilityId": 1,
            "OrganizationId": null,
            "WardTypeId": 4,
            "IsTempWard": false,
            "Code": "JSH01",
            "WardName": "MICU",
            "WardMasterTypeId": 1,
            "Description": "MICU",
            "ServiceRateCategoryId": 2,
            "LocationId": 3,
            "BlockId": 1,
            "StoreMasterId": 1,
            "DisplayOrder": 0,
            "ActiveStatusId": 2,
            "IsActive": true,
            "NoOfDaysFree": 0,
            "ServiceChargeUpgradeFlag": false,
            "DepartmentId": null,
            "GenderId": null,
            "Status": 1,
            "Rev": 1,
            "CreatedBy": 1,
            "CreatedAt": "2023-07-10T06:54:59.000Z",
            "UpdatedBy": 1,
            "UpdatedAt": "2023-09-11T12:10:20.000Z",
            "BedsCount": 10,
            "AvailableBeds": 8,
            "OtherBeds": 0,
            "OccupiedBeds": 2,
            "Facility": {
                "FacilityName": "PHILIPPINE HEART CENTER"
            },
            "ServiceRateCategory": {
                "ServiceRateCategory": "IP TARIFF"
            },
            "LocationMaster": {
                "LocationName": "Second Floor"
            },
            "Block": {
                "Description": "A BLOCK"
            },
            "WardType": {
                "Description": "NORMAL WARD"
            },
            "ActiveStatus": {
                "Description": "Active"
            }
        },
        {
            "Id": 2,
            "FacilityId": 1,
            "OrganizationId": null,
            "WardTypeId": 4,
            "IsTempWard": false,
            "Code": "JSH02",
            "WardName": "SICU",
            "WardMasterTypeId": 1,
            "Description": "SICU",
            "ServiceRateCategoryId": 2,
            "LocationId": 3,
            "BlockId": 1,
            "StoreMasterId": 1,
            "DisplayOrder": 0,
            "ActiveStatusId": 2,
            "IsActive": true,
            "NoOfDaysFree": 0,
            "ServiceChargeUpgradeFlag": false,
            "DepartmentId": null,
            "GenderId": null,
            "Status": 1,
            "Rev": 4,
            "CreatedBy": 1,
            "CreatedAt": "2023-07-11T12:21:32.000Z",
            "UpdatedBy": 1,
            "UpdatedAt": "2023-09-11T12:11:06.000Z",
            "BedsCount": 5,
            "AvailableBeds": 5,
            "OtherBeds": 0,
            "OccupiedBeds": 0,
            "Facility": {
                "FacilityName": "PHILIPPINE HEART CENTER"
            },
            "ServiceRateCategory": {
                "ServiceRateCategory": "IP TARIFF"
            },
            "LocationMaster": {
                "LocationName": "Second Floor"
            },
            "Block": {
                "Description": "A BLOCK"
            },
            "WardType": {
                "Description": "NORMAL WARD"
            },
            "ActiveStatus": {
                "Description": "Active"
            }
        },
        {
            "Id": 3,
            "FacilityId": 1,
            "OrganizationId": null,
            "WardTypeId": 4,
            "IsTempWard": false,
            "Code": "JSH03",
            "WardName": "PICU",
            "WardMasterTypeId": 1,
            "Description": "PICU",
            "ServiceRateCategoryId": 2,
            "LocationId": 3,
            "BlockId": 1,
            "StoreMasterId": 1,
            "DisplayOrder": 0,
            "ActiveStatusId": 2,
            "IsActive": true,
            "NoOfDaysFree": 0,
            "ServiceChargeUpgradeFlag": false,
            "DepartmentId": null,
            "GenderId": null,
            "Status": 1,
            "Rev": 1,
            "CreatedBy": 1,
            "CreatedAt": "2023-07-11T12:24:39.000Z",
            "UpdatedBy": 1,
            "UpdatedAt": "2023-09-11T12:11:53.000Z",
            "BedsCount": 3,
            "AvailableBeds": 3,
            "OtherBeds": 0,
            "OccupiedBeds": 0,
            "Facility": {
                "FacilityName": "PHILIPPINE HEART CENTER"
            },
            "ServiceRateCategory": {
                "ServiceRateCategory": "IP TARIFF"
            },
            "LocationMaster": {
                "LocationName": "Second Floor"
            },
            "Block": {
                "Description": "A BLOCK"
            },
            "WardType": {
                "Description": "NORMAL WARD"
            },
            "ActiveStatus": {
                "Description": "Active"
            }
        },
        {
            "Id": 4,
            "FacilityId": 1,
            "OrganizationId": null,
            "WardTypeId": 4,
            "IsTempWard": false,
            "Code": "JSH04",
            "WardName": "NICU",
            "WardMasterTypeId": 1,
            "Description": "NICU",
            "ServiceRateCategoryId": 2,
            "LocationId": 3,
            "BlockId": 1,
            "StoreMasterId": 1,
            "DisplayOrder": 0,
            "ActiveStatusId": 2,
            "IsActive": true,
            "NoOfDaysFree": 0,
            "ServiceChargeUpgradeFlag": false,
            "DepartmentId": null,
            "GenderId": null,
            "Status": 1,
            "Rev": 6,
            "CreatedBy": 1,
            "CreatedAt": "2023-07-11T12:28:55.000Z",
            "UpdatedBy": 1,
            "UpdatedAt": "2023-09-11T12:12:15.000Z",
            "BedsCount": 3,
            "AvailableBeds": 2,
            "OtherBeds": 0,
            "OccupiedBeds": 1,
            "Facility": {
                "FacilityName": "PHILIPPINE HEART CENTER"
            },
            "ServiceRateCategory": {
                "ServiceRateCategory": "IP TARIFF"
            },
            "LocationMaster": {
                "LocationName": "Second Floor"
            },
            "Block": {
                "Description": "A BLOCK"
            },
            "WardType": {
                "Description": "NORMAL WARD"
            },
            "ActiveStatus": {
                "Description": "Active"
            }
        },
        {
            "Id": 5,
            "FacilityId": 1,
            "OrganizationId": null,
            "WardTypeId": 4,
            "IsTempWard": false,
            "Code": "JSH05",
            "WardName": "PRIVATE",
            "WardMasterTypeId": 1,
            "Description": "PRIVATE",
            "ServiceRateCategoryId": 2,
            "LocationId": 4,
            "BlockId": 1,
            "StoreMasterId": 1,
            "DisplayOrder": 0,
            "ActiveStatusId": 2,
            "IsActive": true,
            "NoOfDaysFree": 0,
            "ServiceChargeUpgradeFlag": false,
            "DepartmentId": null,
            "GenderId": null,
            "Status": 1,
            "Rev": 6,
            "CreatedBy": 1,
            "CreatedAt": "2023-07-11T12:31:32.000Z",
            "UpdatedBy": 1,
            "UpdatedAt": "2023-09-11T12:12:45.000Z",
            "BedsCount": 8,
            "AvailableBeds": 7,
            "OtherBeds": 0,
            "OccupiedBeds": 1,
            "Facility": {
                "FacilityName": "PHILIPPINE HEART CENTER"
            },
            "ServiceRateCategory": {
                "ServiceRateCategory": "IP TARIFF"
            },
            "LocationMaster": {
                "LocationName": "Third Floor"
            },
            "Block": {
                "Description": "A BLOCK"
            },
            "WardType": {
                "Description": "NORMAL WARD"
            },
            "ActiveStatus": {
                "Description": "Active"
            }
        },
        {
            "Id": 6,
            "FacilityId": 1,
            "OrganizationId": null,
            "WardTypeId": 4,
            "IsTempWard": false,
            "Code": "JSH06",
            "WardName": "TWIN SHARING",
            "WardMasterTypeId": 1,
            "Description": "TWIN SHARING",
            "ServiceRateCategoryId": 2,
            "LocationId": 4,
            "BlockId": 1,
            "StoreMasterId": 1,
            "DisplayOrder": 0,
            "ActiveStatusId": 2,
            "IsActive": true,
            "NoOfDaysFree": 0,
            "ServiceChargeUpgradeFlag": false,
            "DepartmentId": null,
            "GenderId": null,
            "Status": 1,
            "Rev": 0,
            "CreatedBy": 1,
            "CreatedAt": "2023-07-12T08:07:02.000Z",
            "UpdatedBy": 1,
            "UpdatedAt": "2023-07-12T08:07:02.000Z",
            "BedsCount": 16,
            "AvailableBeds": 16,
            "OtherBeds": 0,
            "OccupiedBeds": 0,
            "Facility": {
                "FacilityName": "PHILIPPINE HEART CENTER"
            },
            "ServiceRateCategory": {
                "ServiceRateCategory": "IP TARIFF"
            },
            "LocationMaster": {
                "LocationName": "Third Floor"
            },
            "Block": {
                "Description": "A BLOCK"
            },
            "WardType": {
                "Description": "NORMAL WARD"
            },
            "ActiveStatus": {
                "Description": "Active"
            }
        },
        {
            "Id": 7,
            "FacilityId": 1,
            "OrganizationId": null,
            "WardTypeId": 6,
            "IsTempWard": false,
            "Code": "OT1",
            "WardName": "OT-1",
            "WardMasterTypeId": 3,
            "Description": "OT-1",
            "ServiceRateCategoryId": 2,
            "LocationId": 1,
            "BlockId": 1,
            "StoreMasterId": 1,
            "DisplayOrder": 0,
            "ActiveStatusId": 2,
            "IsActive": true,
            "NoOfDaysFree": 0,
            "ServiceChargeUpgradeFlag": false,
            "DepartmentId": null,
            "GenderId": null,
            "Status": 1,
            "Rev": 0,
            "CreatedBy": 1,
            "CreatedAt": "2024-08-21T05:34:04.000Z",
            "UpdatedBy": 1,
            "UpdatedAt": "2024-08-21T05:34:04.000Z",
            "BedsCount": 0,
            "AvailableBeds": 0,
            "OtherBeds": 0,
            "OccupiedBeds": 0,
            "Facility": {
                "FacilityName": "PHILIPPINE HEART CENTER"
            },
            "ServiceRateCategory": {
                "ServiceRateCategory": "IP TARIFF"
            },
            "LocationMaster": {
                "LocationName": "Ground Floor"
            },
            "Block": {
                "Description": "A BLOCK"
            },
            "WardType": {
                "Description": "OT Ward"
            },
            "ActiveStatus": {
                "Description": "Active"
            }
        }
    ],
    "Error": null
}